var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('transition',{attrs:{"enter-class":"opacity-0 transform scale-95","enter-active-class":"transition ease-out duration-100","enter-to-class":"opacity-100 transform scale-100","leave-class":"opacity-100 transform scale-100","leave-active-class":"transition ease-in duration-75","leave-to-class":"opacity-0 transform scale-95"}},[(_vm.isOpen)?_c('div',{staticClass:"absolute right-0 py-2 bg-white border border-gray-300 rounded-lg shadow-md origin-bottom-right bottom-12"},_vm._l((_vm.statuses),function(status,index){return _c('button',{key:index,staticClass:"flex items-center justify-between w-full px-4 py-2 space-x-4 focus:outline-none focus:bg-gray-100 hover:bg-gray-100",on:{"click":function($event){$event.preventDefault();return _vm.onSend(status)}}},[_c('div',{staticClass:"text-sm whitespace-no-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tk('PTicketstatusButton.SubmitAs'))}}),_vm._v(" "),_c('span',{staticClass:"font-semibold",domProps:{"innerHTML":_vm._s(status.name)}})]),_c('div',{staticClass:"w-4 h-4 rounded",class:{
            'bg-yellow-300': status.id === 0,
            'bg-red-500': status.id === 1,
            'bg-blue-400': status.id === 2,
            'bg-gray-500': status.id === 3,
            'bg-gray-400': status.id === 4
          }})])}),0):_vm._e()]),_c('PClickOutside',{attrs:{"do":() => _vm.isOpen = false}},[_c('PButton',{attrs:{"disabled":_vm.loading},on:{"click":_vm.onToggle}},[_c('div',{staticClass:"flex items-center"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$tk('Common.Actions.Submit'))}}),_c('div',{staticClass:"ml-3"},[(_vm.loading)?_c('FontAwesomeIcon',{attrs:{"icon":['far', _vm.isOpen ? 'chevron-down' : 'spinner'],"spin":""}}):_c('FontAwesomeIcon',{attrs:{"icon":['far', _vm.isOpen ? 'chevron-down' : 'chevron-up']}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }